import { DownOutlined } from "@ant-design/icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, Skeleton, Table, Tooltip } from "antd";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SiteContext } from "../../../Contexts";
import APIManager from "../../../scripts/APIManager";
import Card from "../../Card";
import ContextModal from "./ContextModal";
import SpotlightModals from "./SpotlightModals";
import SpotlightsCard from "./SpotlightsCard";
import mainColumns from "./columns";
import expandedColumns from "./expandedColumns";

export default function BugReports(props) {
  const { setActiveModal, openNotification, permissions } =
    useContext(SiteContext);
  const { guildId } = props;
  const [data, setData] = useState(null);
  const [dataCount, setDataCount] = useState(null);
  const [expandedData, setExpandedData] = useState(null);
  const [expandedRecord, setExpandedRecord] = useState(null);
  const [expanded, setExpanded] = useState(null);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeTab, setActiveTab] = useState("Outstanding");
  const [activeTypeTab, setActiveTypeTab] = useState("All");
  const [spotlightsToRemove, setSpotlightsToRemove] = useState(null);
  const [spotlightsToRecover, setSpotlightsToRecover] = useState(null);
  const [spotlightsToClear, setSpotlightsToClear] = useState(null);
  const [spotlightsToAction, setSpotlightsToAction] = useState(null);
  const [actionClassification, setActionClassification] = useState(null);
  const [idsToEmail, setIdsToEmail] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [disableEmailSend, setDisableEmailSend] = useState(false);
  const [emailReport, setEmailReport] = useState(null);
  const [hasHighlighted, setHasHighlighted] = useState(false);
  const [highlightedSpotlight, setHighlightedSpotlight] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(null);
  const [retrievingData, setRetrievingData] = useState(false);
  const [tableSettings, setTableSettings] = useState({
    pagination: null,
    filters: null,
    sorter: null,
  });
  const [channels, setChannels] = useState(null);
  const location = useLocation();
  const feedbackTypes = [
    { value: "Gameplay Mechanics", label: "Gameplay Mechanics" },
    { value: "UI/UX", label: "UI/UX" },
    { value: "Feature Request", label: "Feature Request" },
    { value: "Performance", label: "Performance" },
    { value: "Technical", label: "Technical" },
    { value: "Administrative", label: "Administrative" },
    { value: "Other", label: "Other" },
  ];
  const sentiments = [
    { value: "Negative", label: "Negative" },
    { value: "Constructive", label: "Constructive" },
    { value: "Positive", label: "Positive" },
  ];

  const [reportColumns] = useState({
    MessageID: "messageid",
    ConversationID: "conversationid",
    ConversationFeedbackType: "feedbacktype",
    ConversationHeadline: "headline",
    ConversationStartingTimestamp: "startingtimestamp",
    ConversationEndingTimestamp: "endingtimestamp",
    ConverstaionChannelName: "channelname",
    ConversationNumOfUsers: "numofusers",
    ConversationNumOfMessages: "numofmessages",
    MessageSentiment: "sentiment",
    MessageUsername: "username",
    MessageContent: "content",
    MessageTimestamp: "timestamp",
    MessageReplyCount: "replycount",
    MessageEmojiCount: "emojicount",
  });

  const spotlightActions = [
    {
      label: "Bug",
      key: "Bug",
    },
    {
      label: "Feedback",
      key: "Feedback",
    },
    {
      label: "Help Request",
      key: "Help Request",
    },
    {
      label: "Trash",
      key: "Trash",
      icon: <FontAwesomeIcon className="mr-2" icon={faTrash} size="lg" />,
      danger: true,
    },
  ];

  const requestData = useCallback(() => {
    setRetrievingData(true);

    APIManager.sendRequest(
      "get_feedback_report_count",
      {
        guild_id: guildId,
        start_date: startDate ? new Date(startDate).toISOString() : null,
        end_date: endDate ? new Date(endDate).toISOString() : null,
        starred:
          (tableSettings.filters?.Starred &&
            tableSettings.filters.Starred.length) > 0
            ? tableSettings.filters.Starred[0]
            : null,
        category:
          activeTypeTab === "All"
            ? tableSettings.filters?.feedbacktype || null
            : [activeTypeTab],
        channel_name: tableSettings.filters?.channelname || null,
        actioned:
          activeTab === "Actioned"
            ? true
            : activeTab === "Trash"
            ? null
            : false,
        trashed: activeTab === "Trash" || false,
        actioned_type: tableSettings.filters?.action || null,
      },
      true
    ).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else setDataCount(data.count);
    });

    APIManager.sendRequest(
      "get_feedback_report",
      {
        guild_id: guildId,
        results_per_page: pageSize,
        page_number: pageNumber,
        sort_direction: tableSettings.sorter?.order || "DESC",
        start_date: startDate ? new Date(startDate).toISOString() : null,
        end_date: endDate ? new Date(endDate).toISOString() : null,
        starred:
          (tableSettings.filters?.Starred &&
            tableSettings.filters.Starred.length) > 0
            ? tableSettings.filters.Starred[0]
            : null,
        category:
          activeTypeTab === "All"
            ? tableSettings.filters?.feedbacktype || null
            : [activeTypeTab],
        channel_name: tableSettings.filters?.channelname || null,
        actioned:
          activeTab === "Actioned"
            ? true
            : activeTab === "Trash"
            ? null
            : false,
        trashed: activeTab === "Trash" || false,
        actioned_type: tableSettings.filters?.action || null,
        order_by: tableSettings.sorter?.column
          ? {
              startingtimestamp: "StartingTimestamp",
              channelname: "ChannelName",
              feedbacktype: "FeedbackType",
            }[tableSettings.sorter.field]
          : "StartingTimestamp",
      },
      true
    ).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else setData(data.map((item, index) => ({ ...item, key: index })));
      setRetrievingData(false);
    });
  }, [
    guildId,
    startDate,
    endDate,
    tableSettings,
    pageSize,
    pageNumber,
    activeTab,
    activeTypeTab,
  ]);

  const requestSpotlightHightlight = useCallback(
    async (conv_id) =>
      APIManager.sendRequest(
        "get_highlighted_spotlight",
        {
          guild_id: guildId,
          conv_id,
        },
        true
      ).then((data) => {
        if (data.error) {
          setError(`error: ${data.error}`);
          return null;
        } else return data.position;
      }),
    [guildId]
  );

  const requestChannels = useCallback(() => {
    APIManager.sendRequest(
      "get_analytics_channels",
      {
        guild_id: guildId,
      },
      true
    ).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else
        setChannels(
          Object.entries(data.channels).map(([_, value]) => ({
            value: value,
            text: value,
          }))
        );
    });
  }, [guildId]);

  const updateFeedbackType = useCallback(
    (conv_id, name, category, original_category) => {
      APIManager.sendRequest(
        "update_feedback_type",
        {
          guild_id: guildId,
          conv_id,
          name,
          category,
          original_category,
        },
        true
      ).then((data) => {
        if (data.error)
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
        else if (data.suggested)
          openNotification("success", "Your change has been suggested");
      });
    },
    [guildId, openNotification]
  );

  const updateFeedbackMessageSentiment = useCallback(
    (message_id, name, sentiment, original_sentiment) => {
      APIManager.sendRequest(
        "update_feedback_message_sentiment",
        {
          guild_id: guildId,
          message_id,
          name,
          sentiment,
          original_sentiment,
        },
        true
      ).then((data) => {
        if (data.error)
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
        else if (data.suggested)
          openNotification("success", "Your change has been suggested");
      });
    },
    [guildId, openNotification]
  );

  const markFeedbackAsStarred = useCallback(
    (conv_id, name) => {
      APIManager.sendRequest(
        "mark_feedback_as_starred",
        {
          guild_id: guildId,
          conv_id,
          name,
        },
        true
      ).then((data) => {
        if (data.error)
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
        else if (data.suggested)
          openNotification("success", "Your change has been suggested");
      });
    },
    [guildId, openNotification]
  );

  const markFeedbackAsUnstarred = useCallback(
    (conv_id, name) => {
      APIManager.sendRequest(
        "mark_feedback_as_unstarred",
        {
          guild_id: guildId,
          conv_id,
          name,
        },
        true
      ).then((data) => {
        if (data.error)
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
        else if (data.suggested)
          openNotification("success", "Your change has been suggested");
      });
    },
    [guildId, openNotification]
  );

  const getFeedbackMessages = useCallback(
    async (conv_id) =>
      (() => {
        setExpandedData(null);
        return APIManager.sendRequest(
          "get_feedback_messages",
          {
            guild_id: guildId,
            conv_id,
          },
          true
        );
      })().then((data) => {
        if (data.error) {
          setError(`error: ${data.error}`);
          return null;
        } else
          return data
            .map((item, index) => ({ ...item, key: index }))
            .sort(
              (item1, item2) =>
                item1.startingtimestamp - item2.startingtimestamp
            );
      }),
    [guildId]
  );

  const generateReport = useCallback(
    async (ids) => {
      const messages = await Promise.all(
        ids.map(async (id) =>
          (
            await getFeedbackMessages(id)
          ).map((message) => ({
            ...data[
              data.findIndex(({ conversationid }) => id === conversationid)
            ],
            ...message,
          }))
        )
      );
      const report = [
        Object.keys(reportColumns),
        ...messages
          .flat()
          .map((message) =>
            Object.values(reportColumns).map((column) => message[column])
          ),
      ];
      return report;
    },
    [data, getFeedbackMessages, reportColumns]
  );

  useEffect(() => {
    let today = new Date();
    let oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    setStartDate(dayjs(oneWeekAgo));
    setEndDate(dayjs(today));
  }, []);

  useEffect(() => {
    if (pageNumber === null && !hasHighlighted) {
      const urlParams = new URLSearchParams(location.search);
      let id = urlParams.get("highlight");
      if (id) {
        requestSpotlightHightlight(id).then((position) => {
          if (position) {
            setHighlightedSpotlight(id);
            setHasHighlighted(true);
            setPageNumber(Math.floor(position / 10) + 1);
          }
        });
      } else setPageNumber(1);
    }
  }, [location, data, hasHighlighted, pageNumber, requestSpotlightHightlight]);

  useEffect(() => {
    if (pageNumber !== null && startDate !== null && endDate !== null)
      requestData();
  }, [requestData, pageNumber, startDate, endDate]);

  useEffect(() => {
    requestChannels();
  }, [requestChannels]);

  const columns =
    data && channels
      ? mainColumns(
          data,
          setData,
          activeTab,
          activeTypeTab,
          feedbackTypes,
          channels,
          setSpotlightsToRecover,
          setSpotlightsToClear,
          setSpotlightsToRemove,
          setSpotlightsToAction,
          setActionClassification,
          spotlightActions,
          updateFeedbackType,
          markFeedbackAsStarred,
          markFeedbackAsUnstarred,
          setActiveModal,
          permissions
        )
      : [];

  const messageColumns = expandedData
    ? expandedColumns(
        expandedData,
        setExpandedData,
        feedbackTypes,
        sentiments,
        permissions,
        updateFeedbackMessageSentiment
      )
    : [];

  // Clear selected items on data reload or tab change
  useEffect(() => {
    setSelectedIndexes([]);
    setSelectedItems([]);
  }, [data, activeTab]);

  useEffect(() => {
    if (idsToEmail) {
      setDisableEmailSend(true);
      const createReport = async () => {
        const report = await generateReport(idsToEmail);
        setEmailReport(report);
        setDisableEmailSend(false);
      };
      createReport();
    }
  }, [idsToEmail, data, getFeedbackMessages, generateReport]);

  /*useEffect(() => {
    if (!data) return;
    setPageReport(null);
    const createReport = async () => {
      const report = await generateReport(
        data.filter(filter).map(({ conversationid }) => conversationid)
      );
      setPageReport(report);
    };
    createReport();
  }, [data, filter, generateReport]);*/

  return (
    <>
      <SpotlightsCard
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        activeTypeTab={activeTypeTab}
        setActiveTypeTab={setActiveTypeTab}
        feedbackTypes={feedbackTypes}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        dataCount={dataCount}
        retrievingData={retrievingData}
        selectedItems={selectedItems}
        setSpotlightsToClear={setSpotlightsToClear}
        setSpotlightsToRecover={setSpotlightsToRecover}
        setActionClassification={setActionClassification}
        setSpotlightsToAction={setSpotlightsToAction}
        setSpotlightsToRemove={setSpotlightsToRemove}
        spotlightActions={spotlightActions}
        setIdsToEmail={setIdsToEmail}
        data={data}
        guildId={guildId}
      >
        {data && pageSize && pageNumber ? (
          <>
            <Table
              rowClassName={({ conversationid }) =>
                `cursor-pointer ${
                  conversationid === highlightedSpotlight
                    ? "bg-secondary/25"
                    : ""
                }`
              }
              tableLayout="auto"
              dataSource={data}
              columns={columns}
              loading={retrievingData}
              onChange={(pagination, filters, sorter) => {
                setTableSettings({ pagination, filters, sorter });
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    setExpandedRecord(record);
                    getFeedbackMessages(record.conversationid).then(
                      (result) => {
                        setExpandedData(result);
                      }
                    );
                    setExpanded(true);
                  },
                };
              }}
              pagination={{
                current: pageNumber,
                defaultPageSize: 10,
                pageSize: pageSize,
                hideOnSinglePage: false,
                showSizeChanger: true,
                total: dataCount,
                onChange: (pageChanged, pageSizeChanged) => {
                  //if (pageChanged !== pageNumber)
                  setPageNumber(pageChanged);
                  // if (pageSizeChanged !== pageSize)
                  setPageSize(pageSizeChanged);
                  setHighlightedSpotlight(null);
                },
              }}
              size="small"
              rowSelection={{
                selectedRowKeys: selectedIndexes,
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedIndexes(selectedRowKeys);
                  setSelectedItems(selectedRows);
                },
              }}
            />
          </>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <div className="flex flex-row justify-center items-center">
            <Skeleton active className="mt-2" paragraph={{ rows: 6 }} />
          </div>
        )}
      </SpotlightsCard>
      <ContextModal
        isActive={expanded}
        onClose={() => setExpanded(false)}
        expandedRecord={expandedRecord}
        expandedData={expandedData}
        messageColumns={messageColumns}
        permissions={permissions}
        feedbackTypes={feedbackTypes}
        updateFeedbackType={updateFeedbackType}
        markFeedbackAsStarred={markFeedbackAsStarred}
        markFeedbackAsUnstarred={markFeedbackAsUnstarred}
        data={data}
        setData={setData}
        setSpotlightsToAction={setSpotlightsToAction}
        setSpotlightsToClear={setSpotlightsToClear}
        setSpotlightsToRecover={setSpotlightsToRecover}
        setSpotlightsToRemove={setSpotlightsToRemove}
        setActiveModal={setActiveModal}
        setActionClassification={setActionClassification}
        spotlightActions={spotlightActions}
      />
      <SpotlightModals
        data={data}
        spotlightsToRecover={spotlightsToRecover}
        setSpotlightsToRecover={setSpotlightsToRecover}
        spotlightsToClear={spotlightsToClear}
        setSpotlightsToClear={setSpotlightsToClear}
        spotlightsToRemove={spotlightsToRemove}
        setSpotlightsToRemove={setSpotlightsToRemove}
        spotlightsToAction={spotlightsToAction}
        setSpotlightsToAction={setSpotlightsToAction}
        actionClassification={actionClassification}
        setActionClassification={setActionClassification}
        idsToEmail={idsToEmail}
        setIdsToEmail={setIdsToEmail}
        disableEmailSend={disableEmailSend}
        setDisableEmailSend={setDisableEmailSend}
        emailReport={emailReport}
        reportColumns={reportColumns}
        requestData={requestData}
        guildId={guildId}
      />

      {selectedItems.length > 0 ? (
        <>
          <Card
            className="fixed flex flex-row justify-center w-fit left-0 right-0 bottom-0 mb-6 mx-auto"
            opaque
          >
            <div className="flex flex-row items-center gap-2 m-2">
              <p>
                {selectedItems.length} Item
                {selectedItems.length === 1 ? "" : "s"} Selected:
              </p>
              {activeTab !== "Trash" ? (
                <>
                  <Tooltip
                    title={
                      permissions?.["Action Spotlights"] ||
                      permissions?.["Suggest Analytics Edits"]
                        ? null
                        : "You do not have permission."
                    }
                  >
                    <div>
                      <Dropdown
                        placement="topLeft"
                        trigger={["click"]}
                        disabled={
                          selectedItems.length === 0 ||
                          (!permissions?.["Action Spotlights"] &&
                            !permissions?.["Suggest Analytics Edits"])
                        }
                        menu={{
                          items: spotlightActions,
                          onClick: ({ key }) => {
                            if (key === "Trash") {
                              setSpotlightsToRemove(
                                selectedItems.map(
                                  ({ conversationid, headline }) => ({
                                    id: conversationid,
                                    name: headline,
                                  })
                                )
                              );
                              setActiveModal("remove");
                            } else {
                              setSpotlightsToAction(
                                selectedItems.map(
                                  ({ conversationid, headline }) => ({
                                    id: conversationid,
                                    name: headline,
                                  })
                                )
                              );
                              setActionClassification(key);
                              setActiveModal("action");
                            }
                          },
                        }}
                      >
                        <Button
                          className="font-sans"
                          disabled={
                            !permissions?.["Action Spotlights"] &&
                            !permissions?.["Suggest Analytics Edits"]
                          }
                        >
                          Action <DownOutlined />
                        </Button>
                      </Dropdown>
                    </div>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip
                    title={
                      permissions?.["Action Spotlights"] ||
                      permissions?.["Suggest Analytics Edits"]
                        ? null
                        : "You do not have permission."
                    }
                  >
                    <div>
                      <Button
                        disabled={
                          selectedItems.length === 0 ||
                          (!permissions?.["Action Spotlights"] &&
                            !permissions?.["Suggest Analytics Edits"])
                        }
                        className="font-sans"
                        onClick={() => {
                          setSpotlightsToRecover(
                            selectedItems.map(
                              ({ conversationid, headline }) => ({
                                id: conversationid,
                                name: headline,
                              })
                            )
                          );
                          setActiveModal("recover");
                        }}
                      >
                        Recover
                      </Button>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={
                      permissions?.["Action Spotlights"] ||
                      permissions?.["Suggest Analytics Edits"]
                        ? null
                        : "You do not have permission."
                    }
                  >
                    <div>
                      <Button
                        disabled={
                          selectedItems.length === 0 ||
                          (!permissions?.["Action Spotlights"] &&
                            !permissions?.["Suggest Analytics Edits"])
                        }
                        className="font-sans"
                        onClick={() => {
                          setSpotlightsToClear(
                            selectedItems.map(
                              ({ conversationid, headline }) => ({
                                id: conversationid,
                                name: headline,
                              })
                            )
                          );
                          setActiveModal("clear");
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </Tooltip>
                </>
              )}
              <Button
                disabled={selectedItems.length === 0}
                className="font-sans"
                onClick={() => {
                  setIdsToEmail(
                    selectedItems.map(({ conversationid }) => conversationid)
                  );
                  setActiveModal("email");
                }}
              >
                Create Report
              </Button>
            </div>
          </Card>
        </>
      ) : null}
    </>
  );
}
